<template>
  <div>
    <div class="row m-0" :class="{darkbg: background === 'dark'}">
      <div class="col-lg-6 col-12 pl-lg-0 pr-lg-2 p-0">
        <form-input label="Current" id="current" type="password" v-model.trim="details.currentPassword" :disabled="saving || saved"/>
      </div>
      <div class="col-lg-6 col-12  pr-lg-0 pl-lg-2 p-0">
        <form-input label="New (min 8 characters)" id="new" type="password" v-model.trim="details.newPassword" :disabled="saving || saved"/>
      </div>
    </div>
    <div class="col-md-12 p-0" v-if="changed && !saved && passwordOK">
      <button type="button" class="btn w-100" @click="save()" :disabled="saving || saved">
        <span v-if="saving">Saving...</span>
        <span v-else-if="saved">Saved</span>
        <span v-else>Save</span>
      </button>
    </div>
    <div class="col p-0" v-if="passwordContainsSimbols">
      Your new password can only contain alphanumeric characters!
    </div>
    <div class="col p-0" v-if="saved">
      Password succesfully updated!
    </div>
    <div class="col p-0" v-if="errorMsg">
      {{errorMsg}}
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'ChangePassword',
  props: ['background'],
  components: {
    'form-input': () => import('@/components/FormInput.vue')
  },
  data: () => ({
    details: {
      currentPassword: '',
      newPassword: ''
    },
    errorMsg: null,
    saved: false,
    saving: false
  }),
  computed: {
    changed () {
      const dtls = this.details
      if (dtls.currentPassword && dtls.newPassword && dtls.currentPassword !== dtls.newPassword) {
        return true
      }
      return false
    },
    passwordOK () {
      const patt = /^[a-z0-9]{8,}$/i
      return patt.test(this.details.newPassword)
    },
    passwordContainsSimbols () {
      const patt = /^[a-z0-9]+$/i
      return this.details.newPassword.length > 0 && !patt.test(this.details.newPassword)
    }
  },
  methods: {
    ...mapActions([
      'updatePassword'
    ]),
    save () {
      this.saving = true
      this.errorMsg = null
      this.updatePassword(this.details).then(data => {
        this.saving = false
        if (data.success) {
          this.saved = true
        } else {
          this.errorMsg = data.message
        }
      },
      err => {
        console.error(err)
        this.saving = false
        this.saved = false
        this.errorMsg = err
      })
    }
  }
}
</script>
<style scoped>
.underline {
  text-decoration: underline;
}
.smallicon {
  width: 23px;
  height: 23px;
}
.addicon {
  width: 35px;
  height: 35px;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  width: 24px;
  background-color: #58595B;
}
.darkbg .checkmark {
  height: 13px;
  width: 13px;
  bottom: 2px;
  top: inherit;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--primarycolor);
}
/* When the checkbox is checked, add a blue background */
.darkbg .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.darkbg .container .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid var(--primarycolor);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
